var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ble_result_container",staticStyle:{"margin-top":"-10px"}},[(_vm.$accountLevel > 0)?_c('stats-box',{model:{value:(_vm.avg),callback:function ($$v) {_vm.avg=$$v},expression:"avg"}}):_vm._e(),_c('stats-box-2',{model:{value:(_vm.stats),callback:function ($$v) {_vm.stats=$$v},expression:"stats"}}),_c('div',{staticClass:"html2pdf__page-break"}),_c('v-data-table',{staticClass:"elevation-1 -break",attrs:{"headers":_vm.headers,"items":_vm.datapointsTable,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.pulse",fn:function({ item }){return [_c('v-icon',{staticStyle:{"font-size":"1rem"}},[_vm._v("mdi-heart-outline")]),_vm._v(" "+_vm._s(item.pulse)+" ")]}},{key:"item.status",fn:function({ item }){return [_c('v-badge',{staticStyle:{"font-size":"10px"},attrs:{"color":item.status == 1
            ? 'red'
            : item.status == 2
            ? 'orange'
            : item.status == 3
            ? 'green'
            : 'cyan'}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }