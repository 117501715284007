<template>
  <div class="ble_result_container" style="margin-top: -10px">
    <div class="table">
      <highcharts :options="chartLineOptions"></highcharts>
      <div class="html2pdf__page-break" />
      <stats-box-2 v-model="stats" />
    </div>
    <div class="table">
      <highcharts :options="chartPieOptions"></highcharts>
      <div id="custom-legend">
        <ul>
          <li
            v-for="(point, index) in chartPieOptions.series[0].data"
            :key="index"
          >
            <span
              :style="{ backgroundColor: point.color }"
              class="legend-color"
            ></span>
            <span class="legend-label"
              >{{ point.name }} : {{ point.y || 0 }} %</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="html2pdf__page-break" v-show="$accountLevel > 0" />
    <div class="table" v-show="$accountLevel > 0">
      <highcharts :options="chartBubbleOptions"></highcharts>
    </div>
    <div class="html2pdf__page-break" v-show="$accountLevel > 0" />
    <div class="table" v-show="$accountLevel > 0">
      <highcharts :options="chartHeatmapOptions"></highcharts>
    </div>
  </div>
</template>

<script>
import StatsBox2 from "@/components/StatsBox2.vue";

export default {
  props: [
    "chartLineOptions",
    "chartPieOptions",
    "chartBubbleOptions",
    "chartHeatmapOptions",
    "stats",
  ],
  components: {
    StatsBox2,
  },
  computed: {},
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "./chart.scss";
</style>
