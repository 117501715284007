<template>
  <v-container class="pa-0 ma-0">
    <div style="text-align: center" v-if="loading" class="mt-2">
      <v-progress-circular indeterminate color="#fefefe"></v-progress-circular>
    </div>
    <div
      v-else
      class="content_card"
      style="
        min-height: calc(100vh - 188px);
        display: block;
        padding-bottom: 0px;
      "
    >
      <v-btn
        fixed
        style="top: 70px; z-index: 20"
        icon
        @click="$router.push('/history')"
        class="mb-1"
      >
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>

      <div class="select-custom fixed">
        <v-select
          v-model="datesCount"
          filled
          outlined
          required
          hide-details
          :items="datesCountOptions"
          @change="getClinicalDatapoints(true)"
          dense
          v-if="tabs == 'mobile-tabs-5-2' || tabs == 'mobile-tabs-5-3'"
        ></v-select>
      </div>

      <div class="tab_container">
        <h3
          class="mb-1 mt-1"
          style="color: #e0e0e0; padding-left: 35px; cursor: pointer"
          @click="$router.push('/history')"
        >
          {{ (profile && profile.fullName) || $t("common.nodata") }}
        </h3>

        <v-tabs v-model="tabs" fixed-tabs class="tab_navigation_drawer">
          <v-tab href="#mobile-tabs-5-1"> {{ $t("common.trial") }} </v-tab>
          <v-tab href="#mobile-tabs-5-2" @click="getClinicalDatapoints(false)">
            {{ $t("common.series") }}
          </v-tab>
          <v-tab href="#mobile-tabs-5-3" @click="getClinicalDatapoints(false)">
            {{ $t("common.profile") }}
          </v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="tabs" style="padding-top: 95px" touchless>
        <v-tab-item :value="'mobile-tabs-5-' + 1">
          <h5 v-if="!items.length" class="no_data">
            {{ $t("common.nodata") }}
          </h5>
          <content-item
            v-else
            v-for="(item, index) in items"
            :key="index"
            :item="item"
            @view="view(item)"
          >
            <template v-slot:controller>
              <v-list-item-icon class="pt-4 pr-1" v-if="item.category">
                <verification-icon :category="item.category" />
              </v-list-item-icon>
              <v-list-item-icon class="pt-4 pr-1">
                <span class="mr-2" style="font-size: 0.9rem; margin-top: 2px">{{
                  item.mode ? "24H" : "M"
                }}</span>
              </v-list-item-icon>
            </template>
          </content-item>
        </v-tab-item>
        <v-tab-item :value="'mobile-tabs-5-' + 2" style="position: relative">
          <div style="text-align: center" v-show="loading2" class="mt-2">
            <v-progress-circular
              indeterminate
              color="#fefefe"
            ></v-progress-circular>
          </div>
          <Chart
            v-show="!loading2"
            :chartLineOptions="chartLineOptions"
            :chartPieOptions="chartPieOptions"
            :chartBubbleOptions="chartBubbleOptions"
            :chartHeatmapOptions="chartHeatmapOptions"
            :stats="stats"
          />
        </v-tab-item>
        <v-tab-item :value="'mobile-tabs-5-' + 3">
          <div class="ble_result_container mb-5" style="margin-top: -10px">
            <patient v-model="patientId" :editable="false"></patient>
          </div>
          <Profile
            :stats="stats"
            :avg="avg"
            :headers="headers"
            :datapointsTable="datapointsTable"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-btn
      icon
      color="white"
      style="top: 10px; z-index: 100; position: absolute; right: 20px"
      @click="generateReport"
      :loading="downloading"
      :disabled="!showDownloading"
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-btn
      icon
      color="white"
      style="top: 10px; z-index: 100; position: absolute; right: 65px"
      @click="callPhone(profile.phone)"
      :style="{
        // right: isMobileVersion ? '5px' : '80px',
        // bottom: isMobileVersion ? '20px' : 'auto',
        // top: isMobileVersion ? 'auto' : '10px',
      }"
      v-if="profile && profile.phone && profile.phone != $phone"
    >
      <v-icon>mdi-phone</v-icon>
    </v-btn>
    <v-btn
      icon
      color="white"
      style="top: 10px; z-index: 100; position: absolute; right: 110px"
      :style="{
        // right: isMobileVersion ? '5px' : '140px',
        // bottom: isMobileVersion ? '70px' : 'auto',
        // top: isMobileVersion ? 'auto' : '10px',
      }"
      @click="$store.commit('Mobile/SET_MESSAGE_PHONE_STRING', profile.phone)"
      v-if="profile && profile.phone && profile.phone != $phone"
    >
      <v-icon>mdi-message-text</v-icon>
      <LottieNewMessage :phone="profile.phone" />
    </v-btn>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="$printOptions.enableDownload"
      :preview-modal="$printOptions.previewModal"
      :pdf-quality="2"
      :manual-pagination="true"
      ref="html2Pdf"
      pdf-content-width="560px"
      :html-to-pdf-options="printOptions"
      @beforeDownload="downloading = true"
      @hasDownloaded="hasDownloaded"
    >
      <section slot="pdf-content">
        <div class="printing_container">
          <div class="mt-3">
            <h3 style="margin-bottom: 15px !important">
              {{ $t("common.profile") }}:
            </h3>
            <div
              class="ble_result_container mb-5"
              style="margin-top: -10px; margin-bottom: 20px"
            >
              <patient ref="PatientProfilePrint" :editable="false"></patient>
            </div>
          </div>
          <!-- PDF Content Here -->
          <div class="mt-3">
            <h3 style="margin-bottom: 15px !important">
              {{ $t("common.chart") }}:
            </h3>
            <Chart
              :chartLineOptions="chartLineOptions"
              :chartPieOptions="chartPieOptions"
              :chartBubbleOptions="chartBubbleOptions"
              :chartHeatmapOptions="chartHeatmapOptions"
              :stats="stats"
            />
          </div>
          <div class="html2pdf__page-break" />
          <div class="mt-3">
            <Profile
              :stats="stats"
              :avg="avg"
              :headers="headers"
              :datapointsTable="datapointsTable"
            />
          </div>
          <div class="html2pdf__page-break" />
          <span style="opacity: 0">THE END.</span>
        </div>
      </section>
    </vue-html2pdf>
  </v-container>
</template>

<script>
import Patient from "@/components/patient/Patient.vue";
import VueHtml2pdf from "vue-html2pdf";
import ContentItem from "@/components/cards/ContentItem";
import VerificationIcon from "@/views/monitor/components/VerificationIcon.vue";
import moment from "moment";
import {
  statsLineChartOptions,
  statsPieChartOptions,
  bubbleChartOptions,
  heatmapChartOptions,
} from "@/components/ChartOptions";
import Chart from "./components/Chart.vue";
import Profile from "./components/Profile.vue";
import LottieNewMessage from "@/components/LottieNewMessage.vue";
import { PieChartColorHexCode } from "@/plugins/constants";

export default {
  components: {
    Patient,
    ContentItem,
    Chart,
    Profile,
    VueHtml2pdf,
    LottieNewMessage,
    VerificationIcon,
  },
  computed: {
    datesCountOptions() {
      return [
        { text: `3 ${this.$t("common.date")}`, value: 3 },
        { text: `7 ${this.$t("common.date")}`, value: 7 },
        { text: `30 ${this.$t("common.date")}`, value: 30 },
        { text: `90 ${this.$t("common.date")}`, value: 90 },
        { text: `${this.$t("common.allData")}`, value: null },
      ];
    },
  },
  data() {
    return {
      datesCount: 30,
      tabs: null,
      loading: true,
      loading2: false,
      items: [],
      patientId: null,
      profile: null,
      datapoints: [],
      datapointsTable: [],
      chartLineOptions: {},
      chartPieOptions: {},
      chartBubbleOptions: {},
      chartHeatmapOptions: {},
      avg: {
        pulse: "---",
        sys: "---",
        dia: "---",
      },
      stats: {
        low: 0,
        normal: 0,
        high: 0,
        veryHigh: 0,
      },
      headers: [
        { text: "#", value: "ind", sortable: false },
        { text: this.$t("common.datetime"), value: "date", sortable: false },
        { text: this.$t("common.sys"), value: "sys", sortable: false },
        { text: this.$t("common.dias"), value: "dia", sortable: false },
        { text: this.$t("common.heartRate"), value: "pulse", sortable: false },
        { text: "", value: "status", sortable: false },
      ],
      printOptions: {
        margin: 0,
        filename: `report.pdf`,
        image: {
          type: "jpeg",
          quality: 1,
        },
        enableLinks: true,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: "a5",
          orientation: "portrait",
        },
      },
      downloading: false,
      showDownloading: true,
    };
  },
  async mounted() {
    this.patientId = this.$route.params.id || null;
    await this.getProfile();
    await this.getEpisodes();
    this.$refs.PatientProfilePrint.renderProfile(this.patientId);
    this.$store.commit("Scanner/SET_IS_MEASURING", false);
    // this.getClinicalDatapoints();
  },
  methods: {
    async generateReport() {
      this.downloading = true;
      await this.getClinicalDatapoints(true);
      await this.sleep(2000);
      this.$refs.html2Pdf.generatePdf();
    },
    async hasDownloaded(blob) {
      var url = await this.handleHtml2PdfDownloaded(blob);
      this.downloading = false;
      if (url) this.shareLink(url);
    },
    async getProfile() {
      this.profile = (await this.$dbGet(`patients/${this.patientId}`)) || null;
    },
    async getEpisodes() {
      this.loading = true;
      var episodes = await this.$dbGet(`users/${this.$uid}/episodes`);
      if (!episodes) {
        this.loading = false;
        return;
      }
      episodes = Object.values(episodes)
        .map((e) => ({
          ...e,
          title: e.name,
          category: (e.verification && e.verification.category) || 0,
          mode: e.config && e.config.calibsPerHour,
          content: `
              <span class="mt-2" style="font-size:0.85rem;">
                ${this.$t("common.date")} ${moment(e.date).format(
            "DD-MM-YYYY HH:mm"
          )}
              </span>
              `,
          message: `
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-gauge theme--light" style="font-size: 0.9rem;"></i>
              <span style="font-size:0.8rem;">
                ${e.config && e.config.maxInflatePressure}
              </span>
              <span style="font-size:0.8rem;">mmHg</span>
              <i aria-hidden="true" class="ml-2 v-icon notranslate mdi mdi-heart-outline theme--light" style="font-size: 0.9rem;"></i>
              <span style="font-size:0.8rem;">
                ${e.datacounts || 0}
              </span>
              <i aria-hidden="true" class="ml-2 v-icon notranslate mdi mdi-repeat theme--light" style="font-size: 0.9rem;"></i>
              ${
                e.config && e.config.calibsPerHour
                  ? `<span style="font-size:0.8rem;">
                      ${e.config && e.config.calibsPerHour} ${this.$t(
                      "common.times"
                    )} / h
                    </span>`
                  : `<span style="font-size:0.8rem;">
                      ${this.$t("common.manualMode")}
                    </span>`
              }`,
          image: false,
          item: e,
        }))
        .reverse();

      if (this.patientId == "---") {
        episodes = episodes.filter((e) => !e.patientId);
      } else {
        episodes = episodes.filter((e) => e.patientId == this.patientId);
      }

      this.items = episodes;
      this.loading = false;
    },
    async getClinicalDatapoints(forceReload = false) {
      if (!forceReload) {
        if (this.datapoints.length) return;
      }
      this.loading2 = true;
      var promises = [];
      this.items.forEach(({ id }) => {
        promises.push(this.getEpisodeDatapoints(id));
      });
      var result = [];
      Promise.all(promises).then(async (values) => {
        values.forEach((pack) => {
          result = pack.concat(result);
        });
        this.datapoints = result.filter(
          (i) => i.sys && i.dia && i.pulse && i.date
        );

        if (this.datesCount) {
          this.datapoints = this.datapoints.filter((p) =>
            moment(p.date).isAfter(moment().subtract(this.datesCount, "day"))
          );
        }
        this.datapoints = this.sortDatapoints(this.datapoints);

        await this.sleep(400);
        this.loading2 = false;
        this.renderStatsLineChart();
        this.calculateAvg();
        this.stats = this.calculateStats(this.datapoints);
        this.renderStatsPieChart();
        this.renderDataTable();
        this.renderBubbleChart();
        this.renderHeatmapChart();
      });
    },
    async renderDataTable() {
      this.datapointsTable = this.datapoints.reverse().map((p, ind) => ({
        ...p,
        ind: ind + 1,
        date: moment(p.date).format("DD-MM-YYYY HH:mm"),
        status: this.calibDataSeverity(p),
      }));
    },
    async renderStatsPieChart() {
      this.chartPieOptions = {
        ...statsPieChartOptions,
        series: [
          {
            type: "pie",
            name: this.$t("common.bloodPressure"),
            data: [
              {
                name: this.$t("common.low"),
                y: this.stats.low.percent,
                color: PieChartColorHexCode.Low,
              },
              {
                name: this.$t("common.normal"),
                y: this.stats.normal.percent,
                sliced: true,
                selected: true,
                color: PieChartColorHexCode.Normal,
              },
              {
                name: this.$t("common.high"),
                y: this.stats.high.percent,
                color: PieChartColorHexCode.High,
              },
              {
                name: this.$t("common.veryHigh"),
                y: this.stats.veryHigh.percent,
                color: PieChartColorHexCode.SoHigh,
              },
            ],
          },
        ],
      };
    },
    async renderHeatmapChart() {
      var data = [];
      heatmapChartOptions.yAxis.categories = [];
      for (var i = 0; i <= 23; i++) {
        var hour = i < 13 ? i : i - 12;
        heatmapChartOptions.yAxis.categories.push(
          `${hour < 10 ? "0" : ""}${hour} ${i < 13 ? "am" : "pm"}`
        );
        for (var j = 0; j <= 6; j++) {
          var points = this.datapoints.filter(
            (p) => moment(p.date).day() == j && moment(p.date).hour() == i
          );
          if (points.length) {
            data.push([j, i, this.calibAverage(points, "sys")]);
          } else {
            // data.push([j, i, 120]);
          }
        }
      }
      this.chartHeatmapOptions = {
        ...heatmapChartOptions,
        series: [
          {
            ...heatmapChartOptions.series[0],
            data,
          },
        ],
      };
    },
    async renderBubbleChart() {
      var data = this.datapoints.map((i, ind) => ({
        ind,
        ...i,
        x: moment(i.date).day(),
        y: moment(i.date).hour(),
        z: Math.abs(i.sys),
        color: this.getDataSeverityColor(i),
        opacity: 1,
        marker: {
          lineWidth: 0,
          lineColor: null,
        },
      }));
      this.chartBubbleOptions = {
        ...bubbleChartOptions,
        series: [
          {
            data,
            marker: {
              fillOpacity: 0.6,
            },
            events: {
              click: (e) => {
                var p = e.point;
                var textString = `${this.$t("common.bloodPressure")}: ${
                  p.sys
                } - ${p.dia}, ${p.pulse} bpm\n${this.$t(
                  "common.datetime"
                )}: ${moment(p.date).format("HH:mm DD/MM/YYYY")}`;
                alert(textString);
              },
            },
          },
        ],
      };
    },
    async renderStatsLineChart() {
      var pulses = [];
      var ranges = [];
      var dates = [];
      for (var i = 0; i < this.datapoints.length; i++) {
        var point = this.datapoints[i] || {};
        var { pulse, sys, dia, date } = point;
        if (!pulse || !sys || !dia || !date) continue;
        pulses.push([i, pulse]);
        ranges.push([i, sys, dia]);
        dates.push([i, moment(date).valueOf()]);
      }

      this.chartLineOptions = {
        ...statsLineChartOptions,
        series: [
          {
            name: this.$t("common.heartRate"),
            data: pulses,
            type: "spline",
            zIndex: 2,
            color: "#F06292",
            marker: {
              // radius: 1,
              fillColor: "#e0e0e0",
              lineWidth: 1,
              lineColor: "#EC407A",
            },
          },
          {
            name: this.$t("common.bloodPressure"),
            data: ranges,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: "#E3F2FD",
            fillOpacity: 1,
            zIndex: 1,
            marker: {
              // radius: 1,
              fillColor: "#e0e0e0",
              lineWidth: 1,
              lineColor: "#039BE5",
              symbol: "circle",
              // enabled: false,
            },
          },
          {
            name: this.$t("common.date"),
            data: dates,
            type: "spline",
            zIndex: 0,
            // visible: false,
          },
        ],
      };
    },
    async calculateAvg() {
      this.avg = {
        pulse: this.calibAverage(this.datapoints, "pulse"),
        sys: this.calibAverage(this.datapoints, "sys"),
        dia: this.calibAverage(this.datapoints, "dia"),
      };
    },

    async getEpisodeDatapoints(id) {
      try {
        var datapoints = await this.$dbGet(`episodes/${id}/datapoints`);
        if (!datapoints) return [];
        datapoints = datapoints.filter((p) => p.sys < 350 && p.dia < 350);
        return datapoints;
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    view(item) {
      this.$emit("view", item);
      this.$router.push(`/history/${item.id}`);
    },
  },
};
</script>

<style lang="scss">
.search_patient {
  background-color: #313959;
  border-radius: 12px !important;
  label,
  input {
    margin-top: 0px !important;
    color: #e0e0e0 !important;
  }
}
</style>
