<template>
  <div class="ble_result_container" style="margin-top: -10px">
    <stats-box v-model="avg" v-if="$accountLevel > 0" />
    <stats-box-2 v-model="stats" />
    <div class="html2pdf__page-break" />
    <v-data-table
      :headers="headers"
      :items="datapointsTable"
      class="elevation-1 -break"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.pulse="{ item }">
        <v-icon style="font-size: 1rem">mdi-heart-outline</v-icon>
        {{ item.pulse }}
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.status="{ item }">
        <v-badge
          style="font-size: 10px"
          :color="
            item.status == 1
              ? 'red'
              : item.status == 2
              ? 'orange'
              : item.status == 3
              ? 'green'
              : 'cyan'
          "
        ></v-badge>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import StatsBox from "@/components/StatsBox.vue";
import StatsBox2 from "@/components/StatsBox2.vue";

export default {
  props: ["headers", "datapointsTable", "avg", "stats"],
  components: {
    StatsBox,
    StatsBox2,
  },
  computed: {},
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
};
</script>

<style lang="scss"></style>
